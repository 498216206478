import React, { useState } from "react"
import Footer from "../../components/footer"
import { graphql } from "gatsby"
import "../../styles/cloud-wan.less"
import SEO from "../../components/SEO"
import { ProductNav } from "../../components/header/Header"
import {
  ProductSupport,
  SolutionTopBanner,
  ProductBlog,
  AnimationWrap,
} from "../../components/block"
import { lang } from "../../utils"
// import CloudWanTable from "../../components/table/CloudWanTable"
// const CloudWanTable = require(`../../assets/cloud-wan-table-${lang}.svg`)

// import { lang } from "../../utils"
const CloudWanTable = require(`../../assets/cloud-wan-table-${lang}.svg`)
const CloudWanTableMobile = require(`../../assets/cloud-wan-table-m-${lang}.svg`)
// import { lang } from "../../utils"
import { MapContainer, Feedback, Solution } from "./bare-metal-cloud"
const staticPath = "/products/cloud-wan"
const loadText = require("src/utils").loadText
const url_data = loadText("url")
const static_data = loadText("cloud-wan")
// const B1 = () => (
//   <div className="cloudWan-b1">
//     <div className="font-36 semi-bold t-color">{static_data.b1.title}</div>
//     <div
//       className="mt-16 font-20"
//       dangerouslySetInnerHTML={{ __html: static_data.b1.description }}
//     />
//     <div className="mt-40 block-middle" style={{ maxWidth: "1168px" }}>
//       <img src={`${staticPath}/b1-table.svg`} alt="" />
//     </div>
//   </div>
// )
const B2 = () => (
  <div className="cloudWan-b2">
    <AnimationWrap>
      <div className="cloudWan-b2-content">
        <div className="left">
          <div className="left-content">
            <div className="font-36 semi-bold m-text-center">
              {static_data.b2.title}
            </div>
            <div className="description mt-24 font-20">
              {static_data.b2.description}
            </div>
            <div className="mt-56 imgContainer">
              <img src={`${staticPath}/b2-logo.svg`} alt="" />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="only-desktop">
            <img src={`${staticPath}/b2-stats-${lang}.svg`} alt="" />
          </div>
          <div className="only-mobile">
            <img src={`${staticPath}/b2-stats-m-${lang}.svg`} alt="" />
          </div>
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B3 = () => (
  <div className="cloudWan-b3 ">
    <AnimationWrap>
      <div className="cloudWan-b3-content">
        <div className="left t-color mobile-padding">
          <div className="font-36 semi-bold m-text-center">
            {static_data.b3.title}
          </div>
          <div className="description font-20 mt-24">
            {static_data.b3.description}
          </div>
        </div>
        <div className="right">
          <img
            src="/products/cloud-wan/b3-pic.png"
            alt={static_data.b3.title}
          />
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B4 = () => (
  <div className="cloudWan-b4">
    <AnimationWrap>
      <div className="cloudWan-b4-content mobile-padding">
        <div className="font-36 semi-bold t-color">{static_data.b4.title}</div>
        <div
          className="description mt-24 font-20 block-middle"
          style={{ maxWidth: "1000px" }}
        >
          {static_data.b4.description}
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B5 = () => (
  <div className="cloudWan-b5">
    <AnimationWrap>
      <div className="cloudWan-b5-content t-color">
        <div className="left">
          <div className="font-36 semi-bold m-text-center">
            {static_data.b5.title}
          </div>
          <div className="description mt-24 font-20 m-text-center">
            {static_data.b5.description}
          </div>
        </div>
        <div className="imgContainer">
          <img
            src="/products/cloud-wan/b5-pic.png"
            alt={static_data.b5.title}
          />
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B6 = () => (
  <div className="cloudWan-b6">
    <div className="cloudWan-b6-bg">
      <AnimationWrap>
        <div className="font-36 semi-bold">{static_data.b6.title}</div>
        <div
          className="description font-20 mt-24"
          dangerouslySetInnerHTML={{ __html: static_data.b6.description }}
        />
      </AnimationWrap>
    </div>
  </div>
)
const PopTip = () => (
  <div
    className="cloudWan-popTip font-16 semi-bold mobile-padding"
    dangerouslySetInnerHTML={{ __html: static_data.popTip }}
  />
)
export const Usecase = ({ title, content }) => (
  <div className="cloudWan-usecase">
    <AnimationWrap>
      <div className="font-36 semi-bold t-color">{title}</div>
      <div className="mt-40 page-middle">
        {content.map(({ name, description, img }) => (
          <div className="cloudWan-usecase-item clearfix">
            <div className="right">
              <img src={img} alt={name} />
            </div>
            <div className="left" style={{ minHeight: "140px" }}>
              <div className="font-28 semi-bold t-color">{name}</div>
              <div className="description font-16 mt-24">{description}</div>
            </div>
          </div>
        ))}
      </div>
    </AnimationWrap>
  </div>
)
const Highlight = () => {
  const [active, setActive] = useState(1)

  return (
    <div className="cloudWan-highlights">
      <AnimationWrap>
        <div
          className="cloudWan-highlights-content page-middle only-desktop"
          style={{
            backgroundImage: `url("${staticPath}/highlight${active}-bg.jpg")`,
            backgroundSize: "1440px 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        >
          <div className="font-36 semi-bold">
            {static_data.highlights.title}
          </div>
          {/* <div className="content-wrapper"> */}
          <div style={{ marginTop: "25px" }}>
            <div className="left mt-24">
              {static_data.highlights.content.map(({ name }, idx) => {
                const isActive = active === idx + 1
                return (
                  <div
                    key={idx}
                    className={`item ${isActive ? "active" : ""}`}
                    onMouseEnter={() => {
                      setActive(idx + 1)
                    }}
                  >
                    <img
                      src={`${staticPath}/highlight-icon-${idx + 1}${
                        isActive ? "" : "-w"
                      }.svg`}
                      alt=""
                    />
                    <div className="font-28 semi-bold mt-24">{name}</div>
                  </div>
                )
              })}
            </div>
            <ul className="right font-28">
              {static_data.highlights.content[active - 1].features.map(
                (c, idx) => (
                  <li key={idx} dangerouslySetInnerHTML={{ __html: c }} />
                )
              )}
            </ul>
          </div>
        </div>
        {/* </div> */}
        <div className="only-mobile">
          <ul className="content">
            {static_data.highlights.content.map(({ name, features }, idx) => {
              const key = idx + 1
              const height = [882, 591, 591, 562]
              return (
                <li
                  key={idx}
                  style={{
                    backgroundImage: `url("${staticPath}/highlight${key}-m-bg.jpg")`,
                    height: height[idx],
                  }}
                >
                  {idx === 0 && (
                    <div className="title font-36 semi-bold">
                      {static_data.highlights.title}
                    </div>
                  )}
                  <div className="inner">
                    <div className="name">
                      <div>
                        <img
                          src={`${staticPath}/highlight-icon-${key}.svg`}
                          style={{
                            width: 48,
                          }}
                        />
                        <p className="mt-24 font-24 semi-bold t-color">
                          {name}
                        </p>
                      </div>
                    </div>
                    <div className="features">
                      {features.map((c, i) => (
                        <p key={i} dangerouslySetInnerHTML={{ __html: c }}></p>
                      ))}
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </AnimationWrap>
    </div>
  )
}
const Benefits = () => (
  <div className="cloudWan-benefits">
    <AnimationWrap>
      <div className="font-36 semi-bold t-color">
        {static_data.benefits.title}
      </div>
      <div className="image-container block-middle mt-56">
        <div className="only-desktop">
          <CloudWanTable />
        </div>
        <div className="only-mobile">
          <CloudWanTableMobile />
        </div>
        {/* <img
        className="mt-56"
        src="/products/cloud-wan/benefits-table.svg"
        alt={static_data.benefits.title}
      /> */}
      </div>
    </AnimationWrap>
  </div>
)

export default function Home({ data }) {
  const { allStrapiPosts, strapiTestimonials } = data
  return (
    <div className="cloudWan">
      <SEO {...static_data.seo} featuredImage="/featuredImage/sdwan.jpg" />
      <SolutionTopBanner
        className="sdwan-banner"
        {...static_data.topBanner}
        bgColor="linear-gradient(180deg,rgba(245,246,255,1) 0%,rgba(231,234,255,1) 100%)"
        bgPic={`${staticPath}/topBanner-bg.svg`}
        iconBg={`${staticPath}/iconBg.svg`}
        backgroundAnimated={true}
        animatedIcon={`${staticPath}/animatedIcon.svg`}
        bannerPic={{
          src: `${staticPath}/topBanner-m-bg.svg`,
          style: {
            backgroundPosition: "top center",
          },
        }}
        source="sdwan"
      />
      <ProductNav
        name={static_data.cloudWAN}
        menus={static_data.navigation}
        minWidth={850}
      />
      <a id="overview" />
      {/* <B1 /> */}
      <B2 />
      <B3 />
      <B4 />
      <B5 />
      <B6 />
      <ProductSupport
        bgColor="linear-gradient(#F6F9FE, #E8E6FF)"
        bgPic={`${staticPath}/support-bg.svg`}
        woman={`${staticPath}/support-woman.png`}
        source="sdwan"
      />
      <a id="locations" />
      <MapContainer
        title={static_data.map.title}
        defaultSelected={[url_data.sdwan.name]}
        description={static_data.map.description}
      />
      <PopTip />
      <a id="useCases" />
      <Usecase
        title={static_data.usecases.title}
        content={static_data.usecases.content.map((d, i) => ({
          ...d,
          img: `/products/cloud-wan/usecase-${i + 1}.svg`,
        }))}
      />
      <Highlight />
      <a id="benefits" />
      <Benefits />
      <Feedback {...strapiTestimonials} />
      <Solution
        title={static_data.solution.title}
        description={static_data.solution.description}
      />
      <a id="learnMore" />
      <ProductBlog
        title={static_data.blog.title}
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      />
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        categories: { elemMatch: { name: { eq: "Cloud WAN" } } }
        author: {}
      }
    ) {
      edges {
        node {
          wp_date
          slug
          thumbnail {
            localFile {
              publicURL
            }
          }
          categories {
            name
          }
          content
          created_at
          title
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
    strapiTestimonials(inPage: { eq: "sdwan" }) {
      customerTitle
      customerName
      content
      companyPreview {
        localFile {
          publicURL
        }
      }
      logo {
        localFile {
          publicURL
        }
      }
      companyName
    }
  }
`
